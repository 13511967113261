import { WORDPRESS_API_URL, PROD_ORIGIN } from '~/constants/config';

// Модуль убирает WORDPRESS_API_URL из объекта/массива/строки
// так-же работает с вложенными ключами
// нужен для удобной локальной разработки
// модуль НАМЕРЕННО меняет исходные данные, а не копирует
// чтобы не забить память большими объектами
export function trimWpDomainInPlace<T extends any> (data: T, keys: string[] = []): T {
  if (typeof data === 'string') {
    return data.replace(WORDPRESS_API_URL, '').replace(PROD_ORIGIN, '');
  } else if (Array.isArray(data)) {
    return data.map((item: any) => trimWpDomainInPlace(item, keys));
  } else if (typeof data === 'object') {
    keys.forEach((key) => {
      const [current, ...rest] = key.split('.');
      data[current] = trimWpDomainInPlace(data[current], [rest.join('.')]);
    });
    return data;
  }
  return data;
}
