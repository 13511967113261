interface IBreakpoints {
  [key: number]: string;
};

const breakpointsDefault = {
  0: 'mobile',
  768: 'tablet',
  1024: 'big-tablet',
  1280: 'desktop',
  1920: 'big-desktop',
} as IBreakpoints;

class windowBreakpoints {
  breakpoints: IBreakpoints;
  breakpointCurrent: string;
  resizeCB: (this: Window, ev: UIEvent) => any;

  constructor (breakpoints: IBreakpoints = breakpointsDefault) {
    this.breakpoints = {
      ...breakpointsDefault,
      ...breakpoints,
    };
    this.breakpointCurrent = this.calcBreakpointCurrent();
    this.resizeCB = this.resizeCallback.bind(this);
    this.init();
  }

  init (): void {
    window.addEventListener('resize', this.resizeCB);
  }

  destroy (): void {
    window.removeEventListener('resize', this.resizeCB);
  }

  calcBreakpointCurrent (): string {
    const points: Array<string> = Object.keys(this.breakpoints)
      .sort((a, b) => parseInt(a) - parseInt(b));

    return points.reduce((result: string, point) => {
      if (window.innerWidth >= parseInt(point)) {
        result = this.breakpoints[parseInt(point)] as string;
      }
      return result;
    }, this.breakpoints[parseInt(points[0])]);
  }

  resizeCallback (): void {
    this.breakpointCurrent = this.calcBreakpointCurrent();
  }
}

export default windowBreakpoints;
