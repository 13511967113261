import type { Context } from '@nuxt/types';
import axios, { AxiosRequestConfig } from 'axios';
import { authErrorHandler } from './errorHandlerAuth';
import { getAccessToken, isValidAccessToken, setAccessToken, getSubId } from '@/utils/index';
import { FETCH_USER, GET_IS_SEND_REFRESH_TOKEN, SET_USER_RT_ACTION, TIMEOUT } from '~/constants';
import { normalizeUserData } from '~/libs/normalizeUserData';

import { AUTH_API_URL } from '~/constants/config';

function refreshToken () {
  const accessToken = getAccessToken();

  const axiosInstance = axios.create({
    baseURL: `${AUTH_API_URL}`,
    withCredentials: true,
    timeout: TIMEOUT,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-auth-app': 'web',
    },
  });
  return axiosInstance;
}

export async function authRequestInterceptor (
  config: AxiosRequestConfig,
  ctx: Pick<Context, 'store' | 'redirect' | 'route'>,
): Promise<AxiosRequestConfig> {
  const { store } = ctx;

  const accessToken = getAccessToken();
  const isSendNow = store.getters[GET_IS_SEND_REFRESH_TOKEN];

  // @ts-ignore
  config.headers['x-auth-app'] = 'web';

  if (!isValidAccessToken() && accessToken && !isSendNow) {
    store.dispatch(SET_USER_RT_ACTION, true);

    try {
      const response = await refreshToken().patch('/api/v1/auth/refresh-token');

      const newAccessToken = response?.data?.data?.access_token;
      setAccessToken(newAccessToken);
      await store.dispatch(SET_USER_RT_ACTION, false);

      const userDataCurrent = store.state.user;

      if (!userDataCurrent) {
        const subId = getSubId();
        await store.dispatch(FETCH_USER, { subId, createAxiosInstance: true });
        store.dispatch('talker/user/SET_USER_DATA_ACTION', normalizeUserData(store.state.user));
      }
    } catch (error) {
      store.dispatch(SET_USER_RT_ACTION, false);
      authErrorHandler(error, ctx, `interceptorsRequestAuth ${config}`);
    }
  }
  // Подробнее об этом костыле в репозитории bh-frontend api/README-USERS.MD
  async function delay (): Promise<void> {
    await new Promise<void>((resolve) => {
      let timer = 0;
      const interval = setInterval(checkIsSendNow, 500);

      function checkIsSendNow () {
        const isSendNowActual = store.getters[GET_IS_SEND_REFRESH_TOKEN];
        if (!isSendNowActual) {
          clearInterval(interval);
          resolve();
        }

        if (timer >= 5) {
          clearInterval(interval);
          resolve();
        }
        timer += 0.5;
      }
    });
  }

  await delay();

  const accessTokenActual = getAccessToken();
  if (accessTokenActual) {
    config.headers.Authorization = `Bearer ${accessTokenActual}`;
  }
  config.headers['x-auth-app'] = 'web';

  return config;
}
