export const getRetinaSrcSet = (src?: string, retinaSrc?: string, prefix: string = ''): string | void => {
  if (!src && !retinaSrc) {
    // undefined чтобы атрибут srcset не проставлялся
    return undefined;
  }

  if (!src) {
    // если нет оригинала, то хотя бы ретину отдаем
    return `${prefix}${retinaSrc}`;
  }

  let srcset = `${prefix}${src}`;
  if (retinaSrc) {
    srcset += `, ${prefix}${retinaSrc} 2x`;
  }
  return srcset;
};
