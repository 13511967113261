import { createScriptIfNeeded } from './embeds';
import {
  SPORTS_RU_ENVIRONMENT,
} from '~/constants/config';

type TContent = {
  id: string, // id поста/новости
  type: string, // тип ('news'/'post')
  ctime: string, // время публикации
  url: string, // url поста/новости
  mtime?: string, // время изменения
  image?: string, // ссылка на картинку
  title?: string, // зоголовок
  section?: string, // раздел, к которому относится публикация
  comments?: number, // количество комментариев к посту/новости
  rate?: number, // рейтинг поста/новости
  active?: boolean, // активен ли пост/новость. По умолчанию - true. Стоит передавать этот параметр, если страница контента недоступна/ доступна не всем пользователям
}
type TInitParams = {
  domain: string
  partner: string
  isDev: boolean
  uidAuth?: string
}

type TGetPersonalParams = {
  config: string;
  pagination?: string;
}

type TAnalytic = {
  content?: { id: string, type: string }
  content_list?: Array<{ id: string, type: string }>
  action: string
}

export type TSportsArticle = {
  readonly comments: number;
  readonly domain: string;
  readonly id: string;
  readonly rate: string | null;
  readonly score: number;
  readonly section: string;
  readonly type: string;
  readonly image: string;
  readonly title: string;
  readonly url: string;
};

export interface CustomWindow extends Window {
  SPRTS: {
    senderEventName: string;
    initRecommender: (arg0: TInitParams) => Promise<void>;
    getPersonalRecs: (arg0: TGetPersonalParams) => Promise<void>;
    sendAnalytics: (art0: TAnalytic) => Promise<void>;
    q: any
    sendDataForRecommender: (arg0: any) => Promise<void>;
  };
}

declare const window: CustomWindow;

export async function initSports (userId: string, content: TContent): Promise<void> {
  // ждем загрузки виджета, чтобы был доступ к SPRTS
  await createScriptIfNeeded('/js/sports/sports.js');

  window.addEventListener(window.SPRTS.senderEventName, () => sendDataSports(content));

  const init = {
    domain: 'lifehacker.ru',
    partner: 'lifehacker.ru',
    isDev: SPORTS_RU_ENVIRONMENT === 'development',
    ...(userId && { uidAuth: userId }),
  };

  await window.SPRTS.initRecommender(init);
}

export function sendDataSports (content: TContent) {
  if (window.SPRTS && window.SPRTS.sendDataForRecommender) {
    window.SPRTS.sendDataForRecommender({ content });
  }
}

function getConfig (): string {
  const configs = {
    desktop: 'desktop_underpost',
    tablet: 'tablet_underpost',
    mobile: 'mobile_underpost',
  };

  if (window.innerWidth <= 768) {
    return configs.mobile;
  } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
    return configs.tablet;
  } else {
    return configs.desktop;
  }
}

export async function fetchSportsArticleData (): Promise<any> {
  const config = getConfig();
  const postData = await window.SPRTS.getPersonalRecs({ config });
  return postData;
}
