import jwtDecode from 'jwt-decode';
import { nanoid } from 'nanoid';
import type { Route } from 'vue-router';
import {
  ACCESS_TOKEN_COOKIE_NAME,
  WORDPRESS_API_URL,
  HOME_URL,
  isDevelopment,
} from '~/constants/config';

type ObjectWithKey = {
  key: string,
  [p: string]: any
};

type ObjectNoKey = {
  [p: string]: any
};

export { createObserver } from '~/utils/intersectionObserver';

export const NOOP = () => {};

export const isArray = Array.isArray;

export const isNumber = (value: unknown): boolean => {
  return typeof value === 'number' && isFinite(value);
};

export const isString = (value: unknown): boolean => {
  return typeof value === 'string';
};

export const has = (obj: Record<string, any>, prop: string): boolean => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const isObject = (val: unknown): val is Record<any, any> =>
  val !== null && typeof val === 'object';

// * TOKENS START
export function setAccessToken (accessToken: string) {
  if (accessToken && process.client) {
    window?.localStorage?.setItem(ACCESS_TOKEN_COOKIE_NAME, accessToken);
  }
}

export function getAccessToken (): any {
  if (process.client) {
    return localStorage.getItem(ACCESS_TOKEN_COOKIE_NAME) || '';
  }
}

function decodeJWT (accessToken: string) {
  try {
    const decoded: any = jwtDecode(accessToken);
    return decoded;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error token decode: ${error}`);
    return null;
  }
}

export function getDataFromAccessToken () {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return {};
  }

  const decoded = decodeJWT(accessToken);

  return decoded ?? {};
}

export function getExpAccessToken () {
  const { exp } = getDataFromAccessToken();

  return exp || 0;
}

export function isValidAccessToken (): boolean {
  // * время создания токена в секундах
  const exp = getExpAccessToken();
  //  * текущая дата в секундах
  const dateNow = new Date().getTime() / 1000;

  // * если до протухания токена осталась < 30  - токен невалид
  return exp - dateNow > 30;
}

// deprecated
// использовать _getSubId
export function getSubId (): number {
  if (getAccessToken()) {
    const decoded: any = jwtDecode(getAccessToken());
    return decoded.sub;
  }
  return 0;
}

export function _getSubId (accessToken: string): number {
  if (accessToken) {
    try {
      const decoded: any = jwtDecode(accessToken);
      return decoded.sub;
    } catch (error) {
      return 0;
    }
  }
  return 0;
}
// * TOKENS END

export function isRequiresAuth (route: any): boolean {
  return route.matched.some((record: any) => record.meta.requiresAuth);
}

export function clearUserToken (): void {
  if (process.client) {
    localStorage.removeItem(ACCESS_TOKEN_COOKIE_NAME);
  }
}

export function isRetina (): boolean {
  if (typeof window !== 'undefined') {
    return window.devicePixelRatio >= 2;
  }
  return false;
}

export const isDebugMode = () => typeof window !== 'undefined' && window.location.search.includes('debug=1');

export const getEditLinkHref = (articleId: number): string => `${WORDPRESS_API_URL}/wp-admin/post.php?post=${articleId}&action=edit`;

export const range = (start: number, end: number): Array<number> => Array.from((function*() {
  while (start <= end) {
    yield start++;
  }
})());

// 0 <= result < max
export const random = (max: number): number => Math.floor(Math.random() * max);

// @ts-ignore
export const timeout = (delay: number): Promise<any> => new Promise(resolve => setTimeout(resolve, delay));

export const getUniqueListBy = (arr: Array<any>, key: string): Array<any> => [...new Map(arr.map((item: any) => [item[key], item])).values()];

export const isTouchDevice = (): boolean => {
  return typeof window !== 'undefined' && 'ontouchstart' in window;
};

export const getOrigin = (link: string): string => (new URL(link)).origin;
/**
 * Возвращает объект с уникальным key
 * @param obj Объект без уникального key
 */
export const getObjectWithUniqueKey = (obj: ObjectNoKey): ObjectWithKey => {
  return { ...obj, key: nanoid() };
};

export const isAnotherOrigin = (link: string): boolean => {
  const pat = /^https?:\/\//i;
  return !link.includes(HOME_URL) && pat.test(link);
};

export const isHomeLink = (link: string): boolean => {
  const isInternalLink = ['/wp-content/', '/special/', '/feed', '/promokod', '.pdf', '/kursy'].every(item => !link.includes(item));
  return (link.indexOf(HOME_URL) === 0) && isInternalLink;
};

export const getRelativeLink = (link: string): string => {
  try {
    return link.replace(HOME_URL, '');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return 'error';
  }
};

export const getFullUrl = (slug: string): string => {
  return slug.includes(HOME_URL) ? slug : `${HOME_URL}${slug}`;
};

export function trailingSlash (str: string): string {
  return str.endsWith('/') ? str : str + '/';
}

// TODO
// функции для скрытия/показа шапки сайта, или изменения zIndex'a
// в тех случаях когда открываются полноэкранная галерея изображений
// или форма отправки ошибки.
// решение, судя по всему, вынужденное, нуждается в пересмотре шаблонов
export function topHeaderContainerShow (): void {
  const topHeaderContainer = document.getElementById('top-header-container');
  topHeaderContainer && topHeaderContainer.style.removeProperty('visibility');
}

export function topHeaderContainerHide (): void {
  const topHeaderContainer = document.getElementById('top-header-container');
  topHeaderContainer && (topHeaderContainer.style.visibility = 'hidden');
}

export function lowZindexTopHeaderContainer (): void {
  const topHeaderContainer = document.getElementById('top-header-container');
  if (topHeaderContainer) {
    topHeaderContainer.classList.add('top-header-container--low-zindex');
    topHeaderContainer.classList.remove('top-header-container--high-zindex');
  }
}

export function highZindexTopHeaderContainer (): void {
  const topHeaderContainer = document.getElementById('top-header-container');
  if (topHeaderContainer) {
    topHeaderContainer.classList.add('top-header-container--high-zindex');
    topHeaderContainer.classList.remove('top-header-container--low-zindex');
  }
}

export const getId = (): string => Math.random().toString(16).substring(2);

export const isMobile = (fallback = false): boolean => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 768;
  }
  return fallback;
};

export const isTablet = (fallback = false): boolean => {
  if (typeof window !== 'undefined') {
    return window.innerWidth >= 768 && window.innerWidth < 1280;
  }
  return fallback;
};

export const isDesktop = (fallback = false): boolean => {
  if (typeof window !== 'undefined') {
    return window.innerWidth >= 1280;
  }
  return fallback;
};

export function warn (msg: string): Error | void {
  // eslint-disable-next-line no-console
  console.warn(`[lh-website-client]: ${msg}`);
  if (isDevelopment) {
    return new Error(`[lh-website-client]: ${msg}`);
  }
}

export function error (msg: string): Error | void {
  // eslint-disable-next-line no-console
  console.error(`[lh-website-client]: ${msg}`);
  if (isDevelopment) {
    return new Error(`[lh-website-client]: ${msg}`);
  }
}

export function isElementInViewport (el: HTMLElement): boolean {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const getVersionFromQuery = (query: RouteQuery): string => {
  // Версия для сброса кэша в апи вордпресса. Например, lifehacker.ru/?236712
  // '236712' попадет в version
  const queryKey = Object.keys(query)[0] || '';
  const queryValueIsNull = query[queryKey] === null;
  const queryKeyIsNumber = isNumber(Number(queryKey));

  return (queryValueIsNull && queryKeyIsNumber) ? queryKey : '';
};

export const IN_BROWSER: boolean = (typeof window !== 'undefined');

export function getDaysHoursAndMinutesOutOfMinutes (value: number): Record<string, number> {
  const days = +(Math.floor(value / 60 / 24));
  const hours = +(Math.floor((value / 60) % 24));
  const minutes = +(value % 60);

  return {
    days,
    hours,
    minutes,
  };
}

export function getPrepTimeFormattedSimplified (value: number): string {
  if (!value) {
    return 'время не указано';
  }

  const { days, hours, minutes } = getDaysHoursAndMinutesOutOfMinutes(value);

  let result = '';

  if (days) {
    result = `${days} д.`;
  }

  if (hours) {
    result += result ? ' ' : '';
    result += `${hours} ч.`;
  }

  if (minutes) {
    result += result ? ' ' : '';
    result += `${minutes} мин.`;
  }

  return result;
}

// номер страницы в архивах
export const getActivePage = (route: Route, fallback: number = 1): number => {
  if (typeof route.query.page !== 'undefined') {
    const page = isArray(route.query.page) ? route.query.page[0] : route.query.page;
    if (page !== null) {
      return parseInt(page);
    }
  }
  return fallback;
};

export function openWindowInPopup (url: string): Window | null {
  return window.open(url, 'popUpWindow', 'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
}

export function validateEmail (email: string): boolean {
  if (!email.trim()) {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase()) || email.trim().length === 0;
}

export function validatePhoneByMask (phone: string, mask: string): boolean {
  if (!phone.trim()) {
    return false;
  }
  return phone.length === mask.length;
}

export function removeEridFromUrl (url: string) {
  try {
    const newUrl = new URL(url);
    newUrl.searchParams.delete('erid');
    return newUrl.toString();
  } catch (e) {
    return url;
  }
}
